/* Apply styles to the entire home container */
.home-container {
    padding: 20px; /* Add padding to the entire container */
  }
  
  /* Style header */
  .hero {
    background-image: url(../assests/image5.jpg);
    background-size: cover;
    background-position: center;
    color: #fff;
    padding: 30px;
    text-align: center;
    height: 270px;
  }
  
  .hero-content {
    max-width: 600px;
    margin: 0 auto;
  }
  
  /* Style brief description section */
  .brief-description {
    padding: 30px;
    background-color: #f9f9f9;
    color: #333;
    text-align: justify;
  }
  
  /* Style feature section */
  .features {
    margin-top: 30px;
  }
  
  .feature-card {
    padding: 35px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 25px;
    background-color: #fff;
    text-align: center;
  }
  
  .feature-card h2 {
    margin-top: 10px;
  }
  
  /* Style primary button */
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  /* Style secondary button */
  .btn-secondary {
    background-color: #ffc107;
    border-color: #ffc107;
  }